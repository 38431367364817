<template>
  <div class="page" ref="refPrint" id="page">
    <div class="notice-title h1">{{articleDetail.title}}</div>
    <div class="h4 time">
      <span>【时间】：{{articleDetail.time}}</span>
      <div class="btns">
        <el-button type="text" icon="el-icon-position" @click="share">分享</el-button>
        <el-button type="text" icon="el-icon-printer" @click="print">打印</el-button>
      </div>
    </div>
    <div class="divider-bottom"></div>
    <div style="display: flex;justify-content: center;">
      <!-- <img v-if="articleDetail.pictureUrl" :src="articleDetail.pictureUrl" style="width: 90%;object-fit: fill;" /> -->
    </div>
    <div class="notice-content" v-html="articleDetail.content" v-if="articleDetail.content"></div>
    <el-button type="info" icon="el-icon-refresh-right" v-else>刷新</el-button>
  </div>
</template>

<script>
export default {
  name: 'articleDetail',
  components: {},
  data() {
    return {
      notice_id: null,
      articleDetail: {}
    }
  },
  computed: {

  },
  created(options) {
    this.notice_id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    getDetail() {
      this.$api.share.announcementDetail(this.notice_id).then((res) => {
        this.articleDetail = res.data.data
        // let domain = location.origin
        // console.log(this.articleDetail.content.replace(/<img src="/g,`<img src="${domain}`))
      })
    },
    share() {
      let Url = location.href;
      let oInput = document.createElement('input');
      oInput.value = Url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy");
      // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.$message.success("成功复制链接，请去目标位置粘贴")
    },
    print() {
      this.$nextTick(() => {
        let styleStr = "/printCss/commonPrint.css"
        this.$print(this.$refs.refPrint, styleStr);
      })
    }
  }
}
</script>
<style scoped lang="less">
.page {
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
}
.notice-title {
  text-align: center;
  margin-top: 35px;
  font-weight: 700;
}

.time {
  text-align: center;
  margin: 10px 0;
  position: relative;
  .btns {
    position: absolute;
    top: -9px;
    right: 0;
  }
}

.divider-bottom {
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #cccccc;
  margin: 20px 0;
}

.notice-content {
  margin-top: 30px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
